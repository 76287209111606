var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',{attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{staticClass:"text-left",attrs:{"sm":"4"}},[_c('h4',{staticClass:"m-0 mt-1"},[_vm._v(" "+_vm._s(_vm.topic && _vm.topic.is_schedulable? "Scheduled Communication": "Communication")+": "+_vm._s(_vm.topicName)+" ")]),(_vm.internalTopicName)?_c('h4',{staticClass:"m-0 mt-1"},[_vm._v(" Internal name: "+_vm._s(_vm.internalTopicName)+" ")]):_vm._e()])],1),_c('vue-good-table',{staticClass:"mt-3",attrs:{"mode":"remote","is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.directionIsRTL,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"pagination-options":{
      enabled: true,
      perPage: _vm.perPage,
    },"style-class":"vgt-table striped"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'recipient.full_name')?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"align-items-start mb-0 mt-0"},[_c('router-link',{staticClass:"table-primary-link",attrs:{"to":{
              name: 'champion-program-participant',
              params: { id: _vm.programId, participantId: props.row.recipient_id },
            }}},[_vm._v(" "+_vm._s(props.row.recipient.full_name)+" ")])],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }